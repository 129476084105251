<template>
  <v-app class="">
    <iframe 
      style="padding-top: 60px"
      src="https://h5.ophyer.cn/Test/yzh/3dlw/12/index.html" 
      frameborder="0"
      scrolling="auto"
      width="100%"
      height="100%"
      >
    </iframe>
  </v-app>
</template>

<script>
export default {
  name: 'Course',
  components: {
  },
  data() {
    return {
 
    }
  },
  created() {
 
  },
  mounted() {
  },

}
</script>

<style lang="scss" scoped>


</style>
